import React from 'react'
import PropTypes from 'prop-types'
//import { Link } from 'gatsby'
//import styled from 'styled-components'
import {
  Container,
  //Title,
  //Content,
  //Button,
  //Grid
} from '../components/styled'

const Contact = (props) => (
  <Container>
    <Container.Inner>
      {props.children}
    </Container.Inner>
  </Container>
)

Contact.propTypes = {
  children: PropTypes.node.isRequired,
}

Contact.defaultProps = {
  
}

export default Contact
