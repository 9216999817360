import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import styled from 'styled-components'
import {
	Text,
	Grid
} from '../components/styled'

const StyledQuote = styled.blockquote.attrs(props => ({
	className: `ma0`
}))``

StyledQuote.Wrapper = styled.div.attrs(props => ({
	className: `relative cf center mw6`
}))``

StyledQuote.Background = styled.div.attrs(props => ({
	className: `absolute top-0 w-50 h-100 bg-light-gray`
}))`
	left: 50%;
	transform: translateX(-50%);
	border-radius: 10% 30% 10% 30% / 30% 10% 30% 10%;
`

StyledQuote.Image = styled(Img).attrs(props => ({
	className: `mt4 w-50`
}))`
	border-radius: 10% 30% 10% 30% / 30% 10% 30% 10%;
`

StyledQuote.LogoWrapper = styled.div.attrs(props => ({
	className: `relative z-1 nt4 mb4 w-60 pa3 bg-white`
}))`
	border-radius: 10% 30% 10% 30% / 30% 10% 30% 10%;
`
StyledQuote.Logo = styled(Img).attrs(props => ({
	className: ``
}))``

StyledQuote.Content = styled(Text).attrs(props => ({
	className: `${props.className} center mh0-l f3 tc tl-l`
}))`
	p {
		margin-top: 0;
		&:last-child {
			margin-bottom: 0;
		}
	} 
`

StyledQuote.Source = styled(Text).attrs(props => ({
	className: `${props.className} mt4 center mh0-l measure f5 tc tl-l`
}))`
`

const Quote = (props) => (
	<StyledQuote>
		<Grid className="items-center-l">
			<Grid.Col className={`mb4 mb0-l w-50-l ${(props.reverse && 'order-1-l') || 'order-2-l'}`}>
				<StyledQuote.Wrapper>
					<StyledQuote.Background />
					{props.image &&
						<StyledQuote.Image className={(props.reverse && 'ml-auto') || ''} fluid={props.image.childImageSharp.fluid} />
					}
					{props.logo &&
						<StyledQuote.LogoWrapper className={!props.reverse && 'ml-auto'}>
							<StyledQuote.Logo fluid={props.logo.childImageSharp.fluid} />
						</StyledQuote.LogoWrapper>
					}
				</StyledQuote.Wrapper>
			</Grid.Col>
			<Grid.Col className={`w-50-l ${(props.reverse && 'order-2-l') || 'order-1-l'}`}>
				<StyledQuote.Content dangerouslySetInnerHTML={{__html: props.children}} />
				{props.source &&
					<StyledQuote.Source dangerouslySetInnerHTML={{__html: props.source}} />
				}
			</Grid.Col>
		</Grid>
	</StyledQuote>
)

Quote.propTypes = {
  children: PropTypes.node.isRequired,
  source: PropTypes.string,
  image: PropTypes.object,
  logo: PropTypes.object,
}

Quote.defaultProps = {
  source: ``,
  image: ``,
  logo: ``,
}

export default Quote